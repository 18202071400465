import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Form, Dropdown, DropdownButton, Badge, Col, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUsers, faEye, faUser, faSearch, faBusinessTime, faIndustry, faFileExcel, faArrowRight, faFileImport, faDownload, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";

import { get, post } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';
import { colors } from '../../../tools/constants';
import { pnint } from '../../../tools/tools';


export default function Import() {

    const [step1, setStep1] = React.useState(true);
    const [step2, setStep2] = React.useState(false);
    const [step3, setStep3] = React.useState(false);
    const [step4, setStep4] = React.useState(false);
    const [step5, setStep5] = React.useState(false);

    const [step1File, setStep1File] = React.useState(null);
    const handleStep1File = async (event) => {
        const file = event.target.files[0]
        setStep1File(file)
    }

    const [providers, setProviders] = React.useState([{ id: 1, name: "Proveedor 1" }, { id: 2, name: "Proveedor 2" }, { id: 2, name: "Proveedor 3" }]);
    const [selectedProvider, setSelectedProvider] = React.useState("");

    const PAGE_TITLE = "Importar";
    const PAGE_ICON = faFileImport;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `4-Tech - ${PAGE_TITLE}`;

    }, []);

    const nextStep = () => {
        if (step1) {
            setStep1(false);
            setStep2(true);
        } else if (step2) {
            setSelectedProvider(document.getElementById('selectStep2Provider').value)
            setStep2(false);
            setStep3(true);
        } else if (step3) {
            setStep3(false);
            setStep4(true);
        } else if (step4) {
            setStep4(false);
            setStep5(true);
        }
    }



    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />


            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }} className='d-none d-sm-block'>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>

                    <div className='d-block d-sm-none'>
                        <Row style={{ paddingTop: 10, height: 200 }} >
                            <Col xs={6} className='align-center-vertically-v2'>
                                <p className='m-0' style={{ color: '#A0A0A0', fontSize: 35, fontWeight: 300 }}>{PAGE_TITLE}</p>
                            </Col>
                            <Col xs={6} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 70, color: "A0A0A0" }} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <Row className='content-options content'>
                            <Col className='d-none d-sm-block align-center-vertically-v2 ps-5'>
                                <Link to={'/inicio'} className='link-primary'>
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} /><span className='me-3'>Volver al menú</span>
                                </Link>
                                {step1 ? "Paso 1 | Archivo GBP" : ""}
                                {step2 ? "Paso 2 | Proveedor" : ""}
                                {step3 ? "Paso 3 | Archivo del proveedor" : ""}
                                {step4 ? "Paso 4 | Vista previa" : ""}
                                {step5 ? "Paso 5 | Descarga de archivos" : ""}
                            </Col>
                            <Col className='d-none d-sm-block align-center-vertically-v2 pe-5' style={{ textAlign: 'right' }}>
                            {step4 ? <Button onClick={nextStep}><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Descargar vista previa</Button> : ""}
                            </Col>
                        </Row>

                        <Container style={{ display: (step1 == false ? 'none' : '') }}>
                            <Row>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Cargá el archivo descargado desde GBP</p>
                                    <p className='m-0 pt-4'><Form.Control accept='.xlsx' onChange={(event) => handleStep1File(event)} type="file" /></p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'right' }} className='pb-3'>
                                    <Button onClick={nextStep}>Siguiente <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                                </Col>
                            </Row>
                        </Container>

                        <Container style={{ display: (step2 == false ? 'none' : '') }}>
                            <Row>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faIndustry} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Seleccioná un proveedor</p>
                                    <p className='m-0 pt-4'>
                                        <Form.Select className='' id="selectStep2Provider">
                                            {providers.map((p, i) => (
                                                <option key={i} id={i} value={p.name}>{p.name}</option>
                                            ))}
                                        </Form.Select>
                                    </p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'right' }} className='pb-3'>
                                    <Button onClick={nextStep}>Siguiente <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                                </Col>
                            </Row>
                        </Container>

                        <Container style={{ display: (step3 == false ? 'none' : '') }}>
                            <Row>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Cargá el archivo brindado por {selectedProvider}</p>
                                    <p className='m-0 pt-4'><Form.Control accept='.xlsx' onChange={(event) => handleStep1File(event)} type="file" /></p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'right' }} className='pb-3'>
                                    <Button onClick={nextStep}>Siguiente <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                                </Col>
                            </Row>
                        </Container>

                        <Container style={{ display: (step4 == false ? 'none' : '') }}>
                            <Row>
                                <Col className='pt-5 pb-5'>
                                    <Accordion className='p-0'>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <div>
                                                    <p className='m-0'>Samsung</p>

                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Row style={{display: 'inline-block'}}>
                                                    <Button style={{width: 200}}>Seleccionar todo</Button>
                                                    <Button style={{width: 200}} className='ms-2'>Deseleccionar todo</Button>
                                                    <span><Badge bg='success' className=''><span className='m-1'> </span></Badge> Producto correcto | <Badge bg='warning' className=''><span className='m-1'> </span></Badge> Sin stock | <Badge bg='danger' className=''><span className='m-1'> </span></Badge> Producto no cargado en GBP</span>
                                                </Row>
                                                <Row className='mt-4 p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Badge bg='success' className=''><span className='m-1'> </span></Badge>
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        SMGLXS8
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={5}>
                                                        Samsung Galaxy S8
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        10
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        $500,00 USD
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={``}
                                                            defaultChecked
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='mt-1 p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Badge bg='warning' className=''><span className='m-1'> </span></Badge>
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        SMGLXS9
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={5}>
                                                        Samsung Galaxy S9
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        0
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        $527,52 USD
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={``}
                                                            defaultChecked
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className='mt-1 p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Badge bg='success' className=''><span className='m-1'> </span></Badge>
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        SMGLXS10
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={5}>
                                                        Samsung Galaxy S10
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        10
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        $527,52 USD
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={``}
                                                            defaultChecked
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className='mt-1 p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Badge bg='success' className=''><span className='m-1'> </span></Badge>
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        SMGLXS20
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={5}>
                                                        Samsung Galaxy S20
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        10
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        $527,52 USD
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={``}
                                                            defaultChecked
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className='mt-1 p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Badge bg='success' className=''><span className='m-1'> </span></Badge>
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        SMGLXS21
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={5}>
                                                        Samsung Galaxy S21
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        10
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        $527,52 USD
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Form.Check
                                                            type='checkbox'
                                                            id={``}
                                                            defaultChecked
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row className='mt-1 p-2 mb-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        <Badge bg='danger' className=''><span className='m-1'> </span></Badge>
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        SMGLXS50
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={5}>
                                                        Samsung Galaxy S50
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        10
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={2}>
                                                        $3002,52 USD
                                                    </Col>
                                                    <Col className='align-center-vertically-v2' xs={1}>
                                                        
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textAlign: 'right' }} className='pb-3'>
                                    <Button onClick={nextStep}>Siguiente <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></Button>
                                </Col>
                            </Row>
                        </Container>


                        <Container style={{ display: (step5 == false ? 'none' : '') }}>
                            <Row>
                                <Col>

                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Precios</p>
                                    <p className='m-0 pt-4'><Button><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Descargar</Button></p>
                                </Col>
                                <Col style={{ textAlign: 'center' }} className='pt-5 pb-5'>
                                    <p className='m-0'><FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 60 }}></FontAwesomeIcon></p>
                                    <p className='m-0 pt-2'>Stock</p>
                                    <p className='m-0 pt-4'><Button><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> Descargar</Button></p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            
                        </Container>



                    </Card>
                </Row>
            </Container>
        </div>
    )
}