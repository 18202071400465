import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import './App.css';

import Login from "./components/ui/login/Login";
import Index from "./components/ui/home/Index";
import Home from "./components/ui/home/Home";
import Settings from "./components/ui/settings/Settings";
import UserDetails from "./components/ui/settings/UserDetails";
import MyUserDetails from "./components/ui/settings/MyUserDetails";
import NewUser from "./components/ui/settings/NewUser";
import Import from "./components/ui/products/Import"

import { getUserRole } from "./controllers/user.controller";
import Business from "./components/ui/business/Business";
import BusinessDetails from "./components/ui/business/BusinessDetail";

export function App() {

    return (
        <div>
            <Router>
                <Row className="m-0">

                    <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/importar" element={<Import />} />
                    <Route path="/proveedores" element={<Business />} />
                    <Route path="/proveedores/detalles" element={<BusinessDetails />} />

                    
            
                    <Route path="/sistema" element={<Settings />} />
                    <Route path="/sistema/usuarios/detalles" element={<UserDetails />} />
                    <Route path="/sistema/usuarios/nuevo" element={<NewUser />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    
                </Routes>


                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
        </div>
    );
}