const DEV = false;

const DEV_PARAMS = {
    apiUri: "http://localhost:8080/api",
    homePage: "http://localhost:3001/inicio",
    dataUri: 'https://4tech.secdevstest.com.ar/data'
};

const PROD_PARAMS = {
    apiUri: "",
    homePage: "https://4tech.secdevstest.com.ar/inicio",
    dataUri: 'https://4tech.secdevstest.com.ar/data'
};

const COLORS = {
    green: '#28A745',
    brand_primary: '#49A5DF',
    brand_secondary: '#49A5DF'
}

export const params = function () {
    if(DEV){
        return DEV_PARAMS;
    }else{
        return PROD_PARAMS;
    }
}

export const colors = function () {
    return COLORS;
}