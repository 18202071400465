import React, {useEffect} from 'react'
import {Form, Button, Container, Row, Col, Modal, Badge, Alert} from "react-bootstrap";
import {post} from '../../../controllers/endpoints.controller';
import {saveUserData} from '../../../controllers/user.controller';
import WebFont from 'webfontloader';


export default function Login(){
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordField, setPasswordField] = React.useState(<div></div>);
    const [loginButton, setLoginButton] = React.useState(<div></div>);
    const [error, setError] = React.useState(<div></div>);

    const [showFirstLoginModal, setShowFirstLoginModal] = React.useState(false);
    const handleCloseFirstLoginModal = () => {setShowFirstLoginModal(false);};
    const handleShowFirstLoginModal = () => {
        setShowFirstLoginModal(true);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `4-Tech`;
        setLoginButton(<Button variant="primary" className="w-100" onClick={loginDemo} >Siguiente</Button>)
        
        
    }, []);

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    const login = async function (){
        const body = {
            email: document.getElementById('textEmail').value,
            password: document.getElementById('textPassword').value.toString()
        }

        const req = await post('/users/login', '', body);
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            saveUserData(res);
            window.location.href = '/';
        }else{
            setError(<Alert className='mt-4 w-100 content-table status' variant='danger' style={{textAlign: 'center'}}>{res.message}</Alert>)
        }
    }

    const loginDemo = async function (){
        saveUserData();
        window.location.href = "/inicio"
    }


    const checkIfExists = async function (){
        const body = {
            email: document.getElementById('textEmail').value
        }

        const req = await post('/users/exists', '', body);
        const res = await req.json();

        if(req.status === 200){
            console.log(res)
            if(res.exists == true && res.hasPassword == true){
                console.log('entre')
                setPasswordField(
                    <Form.Group className="mb-4" controlId="formBasicPassword">
                        <Form.Control type="password" id='textPassword' onChange = {(event) => handlePassword(event)} placeholder="Contraseña" />
                    </Form.Group>
                )
                setLoginButton(<Button variant="primary" className="w-100" onClick={login} >Ingresar</Button>)
                setError()
            }else if(res.exists == true && res.hasPassword == false){
                handleShowFirstLoginModal()
                setError()
            }else if(res.exists == false){
                setError(<Alert className='mt-4 w-100 content-table status' variant='danger' style={{textAlign: 'center'}}>No existe el usuario</Alert>)
            }
        }else{
            alert(res.message);
        }
    }

    const firstLogin = async function (){
        const body = {
            email: document.getElementById('textEmail').value,
            password: document.getElementById('textNewPassword').value.toString()
        }

        const req = await post('/users/firstLogin', '', body);
        const res = await req.json();

        if(req.status === 200){
            saveUserData(res);
            window.location.href = '/';
        }else{
            alert(res.message);
        }
    }

    return (
        <div>
            <Row style={{height: '100vh'}}>
                <Col className='d-none d-sm-block' md={8} id='login-background'>
                    
                </Col>
                <Col xs={12} md={4}>
                    <Container id='login-form'>
                        <div style={{textAlign: 'center'}}><img width={200} src='/logo.png'></img></div>
                        <Form className='mt-5'>
                            
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="email" id='textEmail' onChange = {(event) => handleEmail(event)} placeholder="Correo" />
                            </Form.Group>

                            {passwordField}

                            {loginButton}

                            {error}
                        </Form>
                    </Container>
                </Col>
            </Row>

            <Modal show={showFirstLoginModal} onHide={handleCloseFirstLoginModal}>
                <Modal.Header closeButton>
                <Modal.Title>Primer ingreso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Es la primera vez que ingresas al sistema. Por favor, ingrese una nueva contraseña.
                    <Form.Group className='mt-2'>
                        <Form.Text>Contraseña</Form.Text>
                        <Form.Control type="password" id="textNewPassword" placeholder="" />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Confirmar</Form.Text>
                        <Form.Control type="password" id="textNewPasswordConfirm" placeholder="" />
                    </Form.Group>
                    
            
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseFirstLoginModal}>
                    Cerrar
                </Button>

                <Button variant="success" onClick={firstLogin}>Ingresar</Button>
                

                </Modal.Footer>
            </Modal>
        </div>
    );
}