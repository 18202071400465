import {params} from '../tools/constants';

export const saveUserData = function (user){
    /*localStorage.setItem('userToken', user.token);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userSurname', user.surname);
    localStorage.setItem('userAvatar', user.avatar);
    localStorage.setItem('userRole', user.Role.name);
    localStorage.setItem('userId', user.id);*/

    localStorage.setItem('userToken', "");
    localStorage.setItem('userName', "Sergio");
    localStorage.setItem('userSurname', "Civetta");
    localStorage.setItem('userAvatar', "/avatar/demo.png");
    localStorage.setItem('userRole', "Compras");
    localStorage.setItem('userId', 0);
}

export const validateUser = function (){
    const token = localStorage.getItem("userToken");
    if(token !== undefined && token !== null){
        if(token.length > 10){
            //Usuario está logueado
            if(window.location.href !== params.homePage){
                //Estoy en el inicio
                if(getUserRole() == "Cliente"){
                    window.location.href = "/citas";
                }else{
                    window.location.href = "/inicio";
                }
                
            }
            
        }else{
            window.location.href = "/login";
        }
    }else{
        window.location.href = "/login";
    }
}

export const getUserToken = function (){
    return localStorage.getItem('userToken');
}

export const getUserRole = function (){
    return localStorage.getItem('userRole');
}

export const getUserId = function (){
    return localStorage.getItem('userId');
}

export const logout = function (user){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    window.location.href = '/login';
}

export const forceLogout = function (user){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    localStorage.removeItem('userSortExpedientes');
    localStorage.removeItem('userOnlyMineExpedientes');
    window.location.href = '/login';
}

export const saveAvatar = function (avatar){
    localStorage.setItem('userAvatar', avatar);
}