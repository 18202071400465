import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Col, Card, Badge, Button, Table, Dropdown, Form, Modal, ToggleButton, Alert } from "react-bootstrap";
import moment from 'moment'
import { params } from '../../../tools/constants'
import { get, post, del, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faSmile } from '@fortawesome/free-regular-svg-icons'
import { faBell, faCheck, faCoins, faDownload, faEllipsisV, faEye, faFileImport, faGavel, faIndustry, faPlusCircle, faTasks, faTrash } from '@fortawesome/free-solid-svg-icons';
import NavBar from "../navbar/Navbar";
import { processDate, processDateCalendar, processDateEvents, timeDue, timeSince, timeDueWithBadge, pnint } from '../../../tools/tools';
import { pn } from '../../../tools/chains';

export default function Home() {

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });

        document.title = "4-Tech - Inicio";

    }, []);

    const test = () => {
        alert("ok")
    }


    return (
        <div className='p-0 mt-3' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <Container>
                <Row className='pt-5'>
                    <Col xs={3}>
                        <Link to={'/importar'}>
                            <Card className={`statusCard blue`}>
                                <Row className='align-center-vertically-v2 ps-2 pe-2'>
                                    <Col xs={3}>
                                        <FontAwesomeIcon icon={faFileImport} style={{ fontSize: 40 }} className='ps-2 pt-1'></FontAwesomeIcon>
                                    </Col>
                                    <Col>
                                        <Card.Title>Importar lista de proveedor</Card.Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>

                    <Col xs={3}>
                        <Link to={'/proveedores'}>
                            <Card className={`statusCard blue`}>
                                <Row className='align-center-vertically-v2 ps-2 pe-2'>
                                    <Col xs={3}>
                                        <FontAwesomeIcon icon={faIndustry} style={{ fontSize: 40 }} className='ps-2 pt-1'></FontAwesomeIcon>
                                    </Col>
                                    <Col>
                                        <Card.Title className='pt-2'>Proveedores</Card.Title>
                                    </Col>
                                </Row>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}